<template>
  <div>
    <div class=" d-flex d-flex justify-content-between">
      <div class="my-2 d-flex">
        <label for="modulo">
          N° Modulo
        </label>
        <b-form-input
          id="modulo"
          v-model="modulo.id"
          class="mx-2"
          style="width: 100px"
          name="modulo"
          max-length="10"
          required
          type="text"
        />
        <b-form-checkbox
          v-model="modulo.open"
          checked="true"
          class="custom-control-success"
          name="check-button"
          switch
        />
        <span
          v-if="modulo.open"
          style="color: green"
        >Encendido</span>
        <span
          v-else
          style="color: red"
        >Apagado</span>
      </div>
      <div>
        <span>
          Turnos pendientes: <b>{{ turnosPendientes }}</b>
        </span>
      </div>
    </div>
    <b-card
      style="max-width: 22rem"
      title="Turno"
    >
      <b-card-text
        v-if="turn.turno"
        style="heigth: 500px"
      >
        <p>Turno Actual: {{ turn.turno }}</p>
        <p>ID: {{ turn.id }}</p>
        <p>Nombre: {{ turn.nombre }}</p>
        <p>Telefono: {{ turn.telefono }}</p>
        <p>Email: {{ turn.email }}</p>
      </b-card-text>
      <b-card-text
        v-else
        style="height: 160px"
      >
        <b-button
          target="blank"
          variant="primary"
          class="m-4"
          @click="getTurn()"
        >
          Obtener
        </b-button>
      </b-card-text>
    </b-card>
    <b-button
      variant="success"
      class="mr-4"
      @click="atendido()"
    >
      Atendido
    </b-button>
    <b-button
      variant="danger"
      class="mx-4"
      style="heigth: 500px"
      @click="noResponde()"
    >
      No responde
    </b-button>
    <b-button
      variant="outline-primary"
      class="mx-4"
      style="heigth: 500px"
      @click="historiaAtenciones()"
    >
      Historial de atenciones
    </b-button>
    <b-modal
      id="modal-historial-atenciones"
      size="xl"
      hide-footer
      title="Historial De Atenciones"
    >
      <div class="text-center" />
    </b-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      turnosPendientes: 7,
      turn: {},
      modulo: {
        id: null,
        open: false,
      },
      turns: [
        {
          turno: ' V 0622',
          id: 'CC. 1090228224',
          telefono: '3102938890',
          email: 'jhongj@arquitectsoft.com',
        },
        {
          turno: ' V 0623',
          id: 'CC. 1023234224',
          telefono: '3105438890',
          email: 'juanperez@gmail.com',
        },
        {
          turno: ' V 0624',
          id: 'CC. 1143338224',
          telefono: '3102938890',
          email: 'carlosm@gmail.com',
        },
        {
          turno: ' V 0625',
          id: 'CC. 1021328224',
          telefono: '3202938089',
          email: 'maritea@gmail.com',
        },
        {
          turno: ' V 0626',
          id: 'CC. 1045228224',
          telefono: '313294230',
          email: 'juanantonieta22@gmail.com',
        },
      ],
    }
  },

  methods: {
    getTurn() {
      const index = this.getRandomArbitrary(0, 4)
      this.turn = this.turns[index]
    },
    getRandomArbitrary(min, max) {
      const number = Math.random() * (max - min) + min
      return Math.floor(number)
    },
    atendido() {
      this.turn = {}
    },
    noResponde() {
      this.turn = {}
    },
    historiaAtenciones() {
      this.$bvModal.show('modal-historial-atenciones')
    },
  },
}
</script>

<style></style>
